.template-preview-wp-header {
    .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
        margin: 0;
        aspect-ratio: 5/6;
    }
}

.whatsapp-form-fill-up {
    line-height: 3rem;
}

.wp-template-preview-parent {
    position: absolute;
    right: -5px;
    top: -6px;
}

.wp-template-preview {
    @media screen and #{$break-lg-close} {
        position: absolute;
        top: 36px;
        right: 0px;
    }
}

.registered-wp-template-preview{
    position: absolute;
    top: 3rem;
    right: 9rem;
    z-index: 999;

    @media screen and #{$break-xs} {
        right: 1rem;
    }
}