.img-fit-to-div {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.inValidBorder {
    border-color: #f1416c !important;
}

.errorMsg {
    margin-top: 0.3rem;
    color: var(--bs-danger);
    font-size: 1rem;
    font-weight: 400;
}

// horizontal scroll bar design
.horizontal-scroll::-webkit-scrollbar {
    // width: 5px !important;
    height: 5px !important;
}

.horizontal-scroll::-webkit-scrollbar-track {
    background: white;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
    background: #eff2f5;
    border-radius: 50px;
}

.horizontal-scroll::-webkit-scrollbar-thumb:hover {
    background: #dbdbdb;
}

// vertical scroll bar design
.vertical-scroll::-webkit-scrollbar {
    width: 5px !important;
    // height: 5px !important;
}

.vertical-scroll::-webkit-scrollbar-track {
    background: white;
}

.vertical-scroll::-webkit-scrollbar-thumb {
    background: #eff2f5;
    border-radius: 50px;
}

.vertical-scroll::-webkit-scrollbar-thumb:hover {
    background: #dbdbdb;
}

.loader-container {
    min-height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-bar-parent {
    background-color: #c1c1c2;
}

.progress-bar-inside {
    background-color: #1e1e2d;
}

.upload-file-name {
    white-space: nowrap;
    max-width: 8rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-file-display {
    max-width: 18rem;
}

.long-break-word {
    word-break: break-all;
}

.filter-container {
    left: 50%;
    top: 50%;
    transform: translate(51%, 7%);
    z-index: 999;
}

.hidden {
    display: none;
}

.task-filter {
    position: absolute;
    top: 3rem;
    right: 0rem;
    z-index: 999;

    @media screen and #{$break-xs} {
        right: -1rem;
    }
}

.width-fit-content {
    width: fit-content;
}

.text-style-justify{
    text-align: justify;
}

.display-dropdown-loader{
    display: flex;
    justify-content: space-between;
    background-image: none!important;
    padding-right: 1.2rem!important;
}

.hidden-imp{
    display : none !important;
}

.word-break-90{
    word-wrap: break-word;
    width: 90%;
}