.chat-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  transition:  all 0.3s ease;
  min-width: 400px;
}

.show-chat {
  right: 0;
}

.hide-chat {
  right: -300%;
}

.chat-sidebar-body {
  overflow: auto;
  max-height: 80vh;
}

@keyframes slideaway {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.2;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.recording-audio {
  animation: slideaway 1.5s infinite;
  // display: block;
}

.chat-sidebar {

  input[type="range"] {
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    margin: 0 15px;
    width: 200px;
    height: 5px;
    background: rgba(222, 222, 222, 0.6);
    border-radius: 5px;
    background-image: linear-gradient(#009ef7, #009ef7);
    background-size: 0% 0%;
    background-repeat: no-repeat;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #009ef7;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }

  input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
    // background: #ff4500;
  }
}
