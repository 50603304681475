.otp-pop-up {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11111;

    @media screen and #{$break-md-close} {
        right: 0%;
    }
}

.client-pop-up-slider {
    .react-multi-carousel-track{
        padding-bottom: 1px;
    }
    .react-multiple-carousel__arrow--left{
        padding: 1px 2px 0 0;
        left: 0 !important;
    }
    .react-multiple-carousel__arrow--right{
        right: 0 !important;
        padding: 1px 0 0 2px;
    }
    .react-multiple-carousel__arrow{
        min-width: 25px;
        min-height: 25px;
        &::before{
            font-size: 13px;
        }
    }

    // .slick-slide{
    //     padding: 0 5px;
    // }

    // .slick-prev {
    //     left: -11px;
    //     &:hover{
    //         background: none;
    //         color: rgba(93, 92, 92, 0.941);
    //     }
    //     &::before{
    //         content: "\2039";
    //         color: rgba(128, 128, 128, 0.941);
    //         background-color: transparent;
    //         font-size: 45px;
    //     }
    // }

    // .slick-next {
    //     right: -11px;
    //     &:hover{
    //         background: none;
    //         color: rgba(93, 92, 92, 0.941);
    //     }
    //     &::before{
    //         content:"\203A";
    //         color: rgba(128, 128, 128, 0.941);
    //         background-color: transparent;
    //         font-size: 45px;
    //     }
    // }
}