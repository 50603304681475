.login-container {

    .side-image-container {
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../../../../public/media/auth/loginImage3.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @media screen and #{$break-md-close} {
            display: none !important;
        }
    }

    .login-field-container {
        @media screen and #{$break-md-close} {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

}

.login-form-input {
    border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
}

.password-eye {
    position: absolute;
    top: 31px;
    right: 8px;
}

.password-eye-user {
    position: absolute;
    top: 12px;
    right: 8px;
}

.password-eye-with-error {
    position: absolute;
    top: 31px;
    right: 31px;
}

.password-eye-user-with-error {
    position: absolute;
    top: 13px;
    right: 31px;
}