// media query

$break-xs: "(max-width:575px)";
$break-sm: "(min-width:576px) and (max-width:768px)";
$break-md: "(min-width:768px) and (max-width:991px)";
$break-lg: "(min-width:992px) and (max-width:1199px)";
$break-xl: "(min-width:1200px) and (max-width:1399px)";
$break-xxl: "(min-width:1400px)";
$break-xxxl: "(min-width:1400px) and (max-width:1599px)";

$break-sm-open: "(min-width:576px)";
$break-md-open: "(min-width:768px)";
$break-lg-open: "(min-width:992px)";
$break-xl-open: "(min-width:1200px)";

$break-sm-close: "(max-width:768px)";
$break-md-close: "(max-width:991px)";
$break-lg-close: "(max-width:1199px)";
$break-xl-close: "(max-width:1399px)";

// Font Family
$primary-font: "Inter", "Helvetica", "sans-serif";
$primary-color: #5e6278;

// STYLING --> MIXINS
@mixin head1 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 36px;
}

@mixin text1 {
  font-family: $primary-font;
  font-size: 36px;
}

@mixin head2 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 30px;
}

@mixin text2 {
  font-family: $primary-font;
  font-size: 30px;
}

@mixin head3 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 22px;
}

@mixin text3 {
  font-family: $primary-font;
  font-size: 22px;
}

@mixin head4 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 18px;
}

@mixin text4 {
  font-family: $primary-font;
  font-size: 18px;
}

@mixin head5 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 14px;
}

@mixin text5 {
  font-family: $primary-font;
  font-size: 14px;
}

// STYLING --> CSS CLASSES
.head1 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 36px;
}

.text1 {
  font-family: $primary-font;
  font-size: 36px;
}

.head2 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 30px;
}

.text2 {
  font-family: $primary-font;
  font-size: 30px;
}

.head3 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 22px;
}

.text3 {
  font-family: $primary-font;
  font-size: 22px;
}

.head4 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 18px;
}

.text4 {
  font-family: $primary-font;
  font-size: 18px;
}

.head5 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 14px;
}

.text5 {
  font-family: $primary-font;
  font-size: 14px;
}

.head6 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 26px;
}

.text6 {
  font-family: $primary-font;
  font-size: 26px;
}

//task status
.status-1{
  color: $unassigned-color;
  background-color: $unassigned-background-color;
}
.status-border-1{
  border: 1px solid $unassigned-color;
}
.status-2{
  color: $assigned-color;
  background-color: $assigned-background-color;
}
.status-border-2{
  border: 1px solid $assigned-color;
}
.status-3{
  color: $in-progess-color;
  background-color: $in-progess-background-color;
}
.status-border-3{
  border: 1px solid $in-progess-color;
}
.status-4{
  color: $sent-for-check-color;
  background-color: $sent-for-check-background-color;
}
.status-border-4{
  border: 1px solid $sent-for-check-color;
}
.status-5{
  color: $pending-from-client-color;
  background-color: $pending-from-client-background-color;
}
.status-border-5{
  border: 1px solid $pending-from-client-color;
}
.status-6{
  color: $completed-color;
  background-color: $completed-background-color;
}
.status-border-6{
  border: 1px solid $completed-color;
}
.status-7{
  color: $approved-color;
  background-color: $approved-background-color;
}
.status-border-7{
  border: 1px solid $approved-color;
}
.status-8{
  color: $on-hold-color;
  background-color: $on-hold-background-color;
}
.status-border-8{
  border: 1px solid $on-hold-color;
}
.status-9{
  color: $tax-pending-color;
  background-color: $tax-pending-background-color;
}
.status-border-9{
  border: 1px solid $tax-pending-color;
}
.status-10{
  color: $rejected-color;
  background-color: $rejected-background-color;
}
.status-border-10{
  border: 1px solid $rejected-color;
}
.status-11{
  color: $approved-color;
  background-color: $approved-background-color;
}
.status-border-11{
  border: 1px solid $approved-color;
}

//bill status
.bill-status-1{
  color : $billing-pending;
  background-color : $billing-pending-background-color;
}

.bill-status-border-1{
  border: 1px solid $billing-pending;
}

.bill-status-2{
  color : $payment-pending;
  background-color : $payment-pending-background-color;
}

.bill-status-border-2{
  border: 1px solid $payment-pending;
}

.bill-status-3{
  color : $partially-paid;
  background-color : $partially-paid-background-color;
}

.bill-status-border-3{
  border: 1px solid $partially-paid;
}

.bill-status-4{
  color : $fully-paid;
  background-color : $fully-paid-background-color;
}

.bill-status-border-4{
  border: 1px solid $fully-paid;
}

.bill-status-5{
  color : $free-of-cost;
  background-color : $free-of-cost-background-color;
}
.bill-status-border-5{
  border: 1px solid $free-of-cost;
}

.bill-status-6{
  color : $proforma-generated;
  background-color : $proforma-generated-background-color;
}

.bill-status-border-6{
  border: 1px solid $proforma-generated;
}

