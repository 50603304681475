.reset-password-container{
    width: 30%;
    margin : auto;
    position: absolute;
    transform: translate(-25%, 0%);
    // top: 50%;
    left: 50%;

    @media screen and #{$break-lg-close} {
        width: 40%
    }
    @media screen and #{$break-md-close} {
        transform: translate(-50%, 0%);
        width: 50%
    }
    @media screen and #{$break-sm-close} {
        width: 70%
    }
    @media screen and #{$break-xs} {
        width: 90%
    }

    .reset-form-input{
        border: none;
        border-bottom: 1px solid #cccccc;
        border-radius: 0;
    }

    .reset-password-title{
        // font-size: 20px;
        // font-weight: 600;
        padding: 3rem 0 0rem 0;
    }
}

.new-password-container{
    width: 30%;
    margin : auto;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    @media screen and #{$break-lg-close} {
        width: 40%
    }
    @media screen and #{$break-md-close} {
        width: 50%
    }
    @media screen and #{$break-sm-close} {
        width: 70%
    }
    @media screen and #{$break-xs} {
        width: 90%
    }
}