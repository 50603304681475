.add-btn {
    border: none;
    background: none;
    color: #1da9f8;
    font-size: 1.1rem;
}

.cross-btn {
    padding: 5px 11px;
    background-color: #ffdede;
    border-radius: 50%;
}