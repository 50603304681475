.billFormat {
    table {
        // border: 1px solid #bff0fd !important;
        border: 1px solid #eff2f5!important;
    }

    thead {
        // background-color: #bff0fd;
        // background-color: #f1faff; //true
        background-color: #eff2f5;
    }

    tr {
        // border-bottom: 1px solid #bff0fd;
        border-bottom: 1px solid #eff2f5;
    }

    .table-description {
        // border-right: 1px solid #90e5fc;
        border-right: 1px solid #cbd7e3;
    }
}

.formated-bill {
    .modal-dialog {
        max-width: 60%;

        @media screen and #{$break-sm-close} {
            max-width: 80%;
        }

        @media screen and #{$break-xs} {
            max-width: 95%;
        }
    }
}