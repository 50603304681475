/* Custom styles for react-time-picker to match Metronic */
.react-time-picker {
    width: 100%;
    padding: 0%;
  
  .react-time-picker__wrapper {
    border: none;
    background: transparent;
  }
  
  .react-time-picker__inputGroup {
   // background-color: white;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .react-time-picker__inputGroup__input {
    width: 100% !important;
    height: 40px;
    border: 1px solid #e4e6ef;
    margin-right: 4px;
    margin-left: 4px;
    // padding: 0.75rem 1rem;
    padding-left: 4px;
    border-radius: 0.42rem;
    font-size: 1rem;
    background-color: white;
  }
  
  .react-time-picker__inputGroup__leadingZero {
    color: #7e8299;
    display: none;
  }
  
  .react-time-picker__clock {
    border: none;
    background: transparent;
    color: #5e6278;
  }
  
  .react-time-picker__clock__face {
    margin: auto;
    background-color: #f3f6f9;
  }
  
  .react-time-picker__clock__hand {
    background-color: #3699ff;
  }

  
}
/* customTimePicker.scss */
.react-time-picker__wrapper {
  width: 100%;
  border: 1px solid #e4e6ef;
  background-color: #f3f6f9;
  border-radius: 0.42rem;
  padding: 0.75rem 1rem;
}

.react-time-picker__inputGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.react-time-picker__inputGroup__input {
  border: none;
  background: transparent;
  font-size: 1rem;
  color: #3f4254;
}

.react-time-picker__clear-button,
.react-time-picker__clock-button {
  color: #3f4254;
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.react-time-picker__clock {
  border: none;
  background: #f3f6f9;
}
