//task status
$unassigned-color: #e7b914;
// $unassigned-color : #FFD95A;
$unassigned-background-color: #e7b9141a;
// $unassigned-background-color : #FFF7D4;

// $assigned-color : #7e909a;
// $assigned-color : #73A9AD;
$assigned-color: #0aa1dd;
// 3e97ff
// $assigned-color : #674188;
// $assigned-background-color : #7e909a1a;
// $assigned-background-color : #E3F4F4;
$assigned-background-color: #e8f9fd;
// $assigned-background-color : #C3ACD0;

$in-progess-color: #6dd4fd;
$in-progess-background-color: #ac3e311a;

$sent-for-check-color: #ea6a47;
$sent-for-check-background-color: #ea6a471a;

$pending-from-client-color: #ac3e31;
$pending-from-client-background-color: #ac3e311a;

// $completed-color : #1c4e80;
$completed-color: #146c94;
// $completed-background-color : #1c4e801a;
$completed-background-color: #afd3e2;

$approved-color: #50cd89;
$approved-background-color: #50cd891a;

$on-hold-color: #b30202;
$on-hold-background-color: #f218181a;

// $paused-color : #6ab187;
$tax-pending-color: #526d82;
$tax-pending-background-color: #dde6ed;
// $paused-background-color : #6ab1871a;

$rejected-color: #f1416c;
$rejected-background-color: #f1416c1a;

//bill status
$billing-pending: #ffc700;
$billing-pending-background-color: #ffc7001a;

$payment-pending: #f69924;
$payment-pending-background-color: #f699241a;

$partially-paid: #7239ea;
$partially-paid-background-color: #7239ea1a;

$fully-paid: #50cd89;
$fully-paid-background-color: #50cd891a;

$free-of-cost: #546ca4;
$free-of-cost-background-color: #546ca41a;

$proforma-generated: #008b8b;
$proforma-generated-background-color: #008b8b1a;
