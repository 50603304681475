.Toastify__toast--error {
    // border: 1px solid #EB5757;
    border-radius: 9px !important;
    // background: #FAE1E2 !important;
    // background: #ca0f0f !important;
    // background: #ffdddd !important;
    background: #fae5e5 !important;
}
.Toastify__toast--error::after {
//   content : url('../assets/images/svg/closeToast.svg'); // Your svg Path
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
//   content: url("../assets/images/svg/errorToast.svg");// Your svg Path
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}
.Toastify__toast--success {
    // 01aa26 success
    // ca0f0f danger
    // f7f9f6 success
//   border: 1px solid #50cd89 !important;
  border-radius: 9px !important;
//   background: #F0F9FA !important;
//   background: #ddfaea !important;
  background: #dfffe6 !important;
}
.Toastify__toast--success::before {
//   content: url("../assets/images/svg/successToast.svg");// Your svg Path
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}
.Toastify__toast--success::after {
//   content : url('../assets/images/svg/closeToast.svg');// Your svg Path
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}

.Toastify__toast-container {
  @media screen and (max-width : 480px) {
    width : 90vw!important;
    right: 5px !important;
}
}

.Toastify__toast-container--top-right { 
  @media screen and (max-width : 480px) {
    top : 5px !important;
} 
}