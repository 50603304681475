.totalIn{
    transform: rotate(-140deg);
}
.totalOut{
    transform: rotate(45deg);
}

.totalInAmount{
    background-color: #cbf5df;
    color: black !important;
}
.totalOutAmount{
    background-color: #ffd2d2;
    color: black !important;
}
.closingBalanceAmount{
    background-color: #d9e2eb;
    color: black !important;
}

.transaction-shortcut{
    .modal-dialog{
        max-width: 900px;
    }
    .modal-body{
        min-height: 570px;
    }
}