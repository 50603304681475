.dropdown-item{
    color: #5E6278!important;
    transition: color 0.2s ease;
    padding: 0.75rem 1.25rem;
    margin: 0 0;
}

.dropdown-item:active {
    background-color: #eff2f5;
    color: #5E6278;
    transition: color 0.2s ease;
    padding: 0.75rem 1.25rem;
    margin: 0 0;
}

.dropdown-item:focus-visible {
    outline: none;
}

.dropdown-item:focus .dropdown-item:hover {
    background-color: #F9F9F9 !important;
    color: var(--kt-primary) !important;
}

.dropdown-option-selected {
    color: var(--kt-primary)!important;
    background-color: #F9F9F9;

    &:after {
        top: 50%;
        display: block;
        position: absolute;
        transform: translateY(-50%);
        // height: 0.75rem;
        width: 0.75rem;
        content: "\2713";
        mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        // background-color: #009ef7;
        mask-position: center;
        -webkit-mask-position: center;
        right: 1.25rem;
        color: var(--kt-primary);
        font-weight: bolder;
    }
}

// .dropdown-btn{
//     &:after{
//         top: 50%;
//         display: block;
//         position: absolute;
//         transform: translateY(-50%);
//         height: 0.75rem;
//         width: 0.75rem;
//         content: "\2304";
//         mask-repeat: no-repeat;
//         mask-position: center;
//         -webkit-mask-repeat: no-repeat;
//         -webkit-mask-position: center;
//         // background-color: #009ef7;
//         mask-position: center;
//         -webkit-mask-position: center;
//         right: 1.25rem;
//         color: var(--kt-primary);
//         font-weight: bolder;
//     }
// }

.dropdown-item-style {
    color: #5E6278;
    transition: color 0.2s ease;
    padding: 0.75rem 1.25rem;
    margin: 0 0;

    &:hover {
        background-color: #F9F9F9;
        color: #009ef7;
        transition: color 0.2s ease;
    }
}

.dropdown-search{
    background-color: var(--bs-body-bg);
    padding: 0.55rem 0.75rem;
    color: var(--bs-gray-700);
    font-size: 0.95rem;
    border: 1px solid var(--bs-gray-300);
    border-radius: 0.425rem;
    outline: 0 !important;
}