.sub-task-parent {
    background-color: #E9EBEB;
}

.single-sub-task::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #cdcdcd;
    display: inline-flex;
    line-height: 13px;
    align-items: center;
    vertical-align: middle;
    margin: 0 8px 1px 5px;
}

.single-task-due-date {
    background-color: #E9EBEB;
    padding: 2px 6px;
}

.single-task-status-dropdown {
    border: none;
    border-bottom: 3px solid #BCC3C5;
}

.single-task-upload-file-btn {
    background-color: #E9EBEB;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single-task-comment-box {
    border-radius: 60px;
    padding: 0 10px;
}

.single-task-comment-box-input {
    width: 97%;
    border: none;
}

.single-task-comment-box-input:focus-visible {
    border: none;
    outline: none;
}

.single-task-user::after {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #cdcdcd;
    display: inline-flex;
    line-height: 13px;
    align-items: center;
    vertical-align: middle;
    margin: 0 5px 1px 5px;
}

.single-task-remarks::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #cdcdcd;
    display: inline-flex;
    line-height: 13px;
    align-items: center;
    vertical-align: middle;
    margin: 0 8px 1px 5px;
}

.uploaded-file-name {
    white-space: nowrap;
    width: 12em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.uploaded-file-name-remark {
    white-space: nowrap;
    width: 4em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pending-task-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: linear-gradient(270deg, #fdcd3b 50%, #ffffff 50%)
}

.active-task-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #3a58d4;
}

.active-task-icon-parent {
    background: #e8effd;
}

.completed-task-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #3dd4a6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-pop-up {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 120;

    @media screen and #{$break-md-close} {
        right: 0%;
    }
}

.pop-up-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    // backdrop-filter: blur(1px);
    z-index: 111;
}

.checkbox-input {
    display: none;

    &:checked+label .checkbox {
        &:after {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &:checked+label .full-checkbox {
        &:after {
            transform: translate(-50%, -50%) scale(1);
        }
    }
}

.checkbox {
    border: 1px solid #b1b3b4;
    border-radius: 0.45rem;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    // background-color: #eff2f5;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 10px;
        height: 10px;
        background-color: #009ef7;
        border-radius: 3px;
        transition: 0.3s;
    }
}

.full-checkbox {
    border: 1px solid #b1b3b4;
    border-radius: 0.45rem;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;

    &:after {
        content: '\2713';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 20px;
        height: 20px;
        color: #ffffff;
        background-color: #009ef7;
        border-radius: 0.45rem;
        transition: 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

li {
    position: static;
}

.custom-date-picker {
    background-color: transparent !important;
}

.custom-date-picker:focus-visible {
    outline: none;
}

.completion-field-pop-up {
    position: absolute;
    top: 115%;
    right: 29%;
}